<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('external_sidebar.profile') + ' ' + $t('globalTrans.update') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" enctype="multipart/form-data">
                    <b-overlay :show="unitLoad">
                        <b-row>
                            <b-col>
                                <h5 class="ex_form_title">{{ $t('globalTrans.personal_information') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row class="mb-3">
                          <b-col sm="3">
                            <ValidationProvider name="Name (En)" vid="name" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="name"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                              </template>
                                  <b-form-input
                                  id="name"
                                  disabled
                                  v-model="profile.name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                              </template>
                                  <b-form-input
                                  id="name_bn"
                                  disabled
                                  v-model="profile.name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Father Name (En)" vid="father_name">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="father_name"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.father_name_en')}}
                              </template>
                                  <b-form-input
                                  id="father_name"
                                  v-model="profile.father_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Father Name (Bn)" vid="father_name_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="father_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.father_name_bn')}}
                              </template>
                                  <b-form-input
                                  id="father_name_bn"
                                  v-model="profile.father_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Mother Name (En)" vid="mother_name">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="mother_name"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.mother_name_en')}}
                              </template>
                                  <b-form-input
                                  id="mother_name"
                                  v-model="profile.mother_name"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="mother_name_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('globalTrans.mother_name_bn') }}
                              </template>
                                  <b-form-input
                                  id="mother_name_bn"
                                  v-model="profile.mother_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|min:11|max:11">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="mobile_no"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                              </template>
                                  <b-form-input
                                  id="mobile_no"
                                  disabled
                                  v-model="profile.mobile_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Email" vid="email">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="email"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.email')}}
                              </template>
                                  <b-form-input
                                  disabled
                                  id="email"
                                  v-model="profile.email"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Profile Image" vid="profile_image" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="profile_image"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('external_research.profile_image')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-file
                                  id="profile_image"
                                  v-on:change="onFileChange"
                                  accept="image/*"
                                  v-model="profile.profile_image"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="NID No" vid="nid" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="nid"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.nid')}} <span class="text-danger">*</span>
                              </template>
                                  <b-form-input
                                  id="nid"
                                  v-model="profile.nid"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="NID Image" vid="nid_image">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="nid_image"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.nid_photo')}}
                              </template>
                                <b-form-file
                                  id="nid_image"
                                  v-on:change="onFileChange2"
                                  accept="image/*"
                                  v-model="profile.nid_image"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="gender"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalUserData.gender')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="profile.gender"
                                  id="gender"
                                  :options="genderList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Occupation (Bn)" vid="occupation">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="occupation"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.occupation_bn')}}
                              </template>
                                  <b-form-input
                                  id="occupation"
                                  v-model="profile.occupation"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Occupation (En)" vid="occupation_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="occupation_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.occupation_en')}}
                              </template>
                                  <b-form-input
                                  id="occupation_bn"
                                  v-model="profile.occupation_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h5 class="ex_form_title">{{ $t('globalTrans.address') }}</h5>
                            </b-col>
                        </b-row>
                        <!---Address Start-->
                        <b-row class="mb-3">
                          <b-col sm="3">
                            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="area_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('org_pro.area_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.area_type_id"
                                  :options="areaTypeList"
                                  id="area_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="division_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.division_id"
                                  :options="divisionList"
                                  id="division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3" v-if="profile.area_type_id === 1">
                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="city_corporation_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('globalTrans.city_corporation')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.city_corporation_id"
                                  :options="cityCorporationList"
                                  id="city_corporation_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3" v-if="profile.area_type_id === 2 || profile.area_type_id === 3">
                            <ValidationProvider name="Upazila" vid="upazila_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="upazila_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('org_pro_upazilla.upazilla')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.upazila_id"
                                  :options="upazilaList"
                                  id="upazila_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3" v-if="profile.area_type_id === 2">
                            <ValidationProvider name="Pauroshova" vid="pouroshova_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pouroshova_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('globalTrans.pouroshova')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.pouroshova_id"
                                  :options="pauroshovaList"
                                  id="pouroshova_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3" v-if="profile.area_type_id === 3">
                            <ValidationProvider name="Union" vid="union_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="union_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('org_pro_union.union')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="profile.union_id"
                                  :options="unionList"
                                  id="union_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Village (En)" vid="village">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="village"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.village_en')}}
                              </template>
                                  <b-form-input
                                  id="village"
                                  v-model="profile.village"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Village (Bn)" vid="village_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="village_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.village_bn')}}
                              </template>
                                  <b-form-input
                                  id="village_bn"
                                  v-model="profile.village_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Address (En)" vid="address_en">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="address_en"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.address_en')}}
                              </template>
                                  <b-form-input
                                  id="address_en"
                                  v-model="profile.address_en"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col sm="3">
                            <ValidationProvider name="Address (Bn)" vid="address_bn">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="address_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('globalTrans.address_bn')}}
                              </template>
                                  <b-form-input
                                  id="address_bn"
                                  v-model="profile.address_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <div class="text-right">
                          <b-button type="submit" variant="primary">{{ saveBtnName }}</b-button>
                        </div>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { profileListDataStore } from '../../api/routes'
import { EventBus } from '@/EventBusLayout'
import { mapGetters } from 'vuex'

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
    unitLoad: false,
      saveBtnName: this.$t('globalTrans.update'),
      id: 0,
      profile: {
        user_id: 0,
        profile_image: [],
        profile_image_file: '',
        nid_image: [],
        nid_image_file: '',
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        mobile_no: '',
        nid: '',
        gender: 0,
        email: '',
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        city_corporation_id: 0,
        pouroshova_id: 0,
        upazila_id: 0,
        union_id: 0,
        village: '',
        village_bn: '',
        occupation: '',
        occupation_bn: '',
        address_en: '',
        address_bn: ''
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      cityCorporationList: [],
      pauroshovaList: [],
      loading: false
    }
  },
  created () {
  },
  mounted () {
      const commonProfile = this.getCommonProfile()
      this.profile.user_id = this.authUser.user_id
      this.profile.email = this.authUser.email
      this.profile.name = commonProfile.name
      this.profile.name_bn = commonProfile.name_bn
      this.profile.mobile_no = this.authUser.mobile_no
      this.profile.father_name = commonProfile.father_name
      this.profile.father_name_bn = commonProfile.father_name_bn
      this.profile.mother_name = commonProfile.mother_name
      this.profile.mother_name_bn = commonProfile.mother_name_bn
      this.profile.nid = commonProfile.nid
      this.profile.gender = commonProfile.gender
    core.index()
  },
  computed: {
    divisionList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
    },
    areaTypeList () {
      const objectData = this.$store.state.commonObj.wardTypeList
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
    },
    genderList: function () {
        return this.$store.state.ExternalUserIrrigation.genderList.map(Obj => {
            if (this.$i18n.locale === 'bn') {
                return { value: Obj.value, text: Obj.text_bn }
            } else {
                return { value: Obj.value, text: Obj.text_en }
            }
        })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    ...mapGetters({
        authUser: 'Auth/authUser'
    })
  },
  watch: {
    'profile.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.districtList = this.getDistrictList(newVal)
        } else {
          this.districtList = []
        }
    },
    'profile.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        this.cityCorporationList = this.getCityCorporationList(newVal)
      } else {
        this.upazilaList = []
        this.cityCorporationList = []
      }
    },
    'profile.upazila_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pauroshovaList = this.getPauroshovaList(newVal)
      } else {
        this.unionList = []
        this.pauroshovaList = []
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.profile.org_id) {
            this.getOfficeTypeList()
        }
      }
    }
  },
  methods: {
    getCommonProfile () {
      return this.$store.state.ExternalUserIrrigation.commonProfile
    },
    onFileChange (e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.profile.profile_image_file = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
          this.profile.profile_image_file = ''
      }
    },
    onFileChange2 (e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.profile.nid_image_file = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
          this.profile.nid_image_file = ''
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const UpaZila = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return UpaZila.filter(upazila => upazila.district_id === districtId)
      }
      return UpaZila
    },
    getUnionList (upazilaId) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === parseInt(districtId))
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
    },
    getPauroshovaList (upazilaId) {
      const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.status === 0 && item.upazilla_id === parseInt(upazilaId))
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
    },
    async register () {
        this.unitLoad = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: true }

        if (this.authUser.user_id) {
            result = await RestApi.postData(agriResearchServiceBaseUrl, profileListDataStore, this.profile)
        }
        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$store.dispatch('ExternalAgriResearch/mutateAgriResearchExternalDropdown', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save'),
                color: '#D6E09B'
            })
            EventBus.$emit('RESEARCH_FARMER')
            this.$router.push({ path: '/research-farmer/profile' })
        } else {
            this.unitLoad = false
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'Operation failed! Please, try again.'
            })
        }
    }
  }
}
</script>
